import { useContext, useState } from "react"
import { CampaignContext } from "../../contexts/CampaignContext"
import classes from "./Notification.module.css"
import close from "../../assets/close.svg"

const Notification = () => {
  const [toggled, setToggled] = useState(false)
  const { changeLog } = useContext(CampaignContext)

  const checkIfError = result => {
    if (/(increased|decreased)/.test(result)) {
      return true
    } else return false
  }

  const console = (
    <div className={classes.consoleContainer}>
      <img
        src={close}
        className={classes.close}
        onClick={() => setToggled(false)}
      />
      {changeLog.map(record => {
        if (checkIfError(record)) {
          return <div className={classes.result}>{record}</div>
        } else return <div className={classes.error}>{record}</div>
      })}
    </div>
  )

  return (
    <div>
      {toggled
        ? console
        : changeLog.length >= 1 && (
            <div className={classes.count} onClick={() => setToggled(true)}>
              {changeLog.length} new{" "}
              {changeLog.length >= 2 ? "changes" : "change"}
            </div>
          )}
    </div>
  )
}

export default Notification
