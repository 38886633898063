export const changePriority = async (modalParams, token) => {
  const currentCampaign = modalParams.currentCampaign
  var { name, skill, priority: oldPriority } = currentCampaign
  let priority = modalParams.newPriority
  var objectValues = Object.values(currentCampaign).filter(value => value != "")
  if (objectValues.length === 3) {
    try {
      const result = await fetch(
        `https://little-hypnotic-epoxy.glitch.me/change_priority/${name}/${skill}/${priority}/${oldPriority}/NULL`.trim(),
        {
          headers: {
            authorization: token,
          },
        }
      )
      return result.status
    } catch (err) {
      return err
    }
  }

  if (objectValues.length === 4) {
    try {
      const result = await fetch(
        `https://little-hypnotic-epoxy.glitch.me/campaigns/change_priority/${name}/${skill}/${priority}/${oldPriority}/${objectValues[3]}`.trim(),
        {
          headers: {
            authorization: token,
          },
        }
      )
      return result.status
    } catch (err) {
      return err
    }
  }
}
