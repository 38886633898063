export const campaignSorter = campaigns => {
  let levelOnes = campaigns.filter(campaign => {
    return campaign.priority == "90"
  })
  let levelTwos = campaigns.filter(campaign => {
    return campaign.priority == "80"
  })
  let levelThrees = campaigns.filter(campaign => {
    return campaign.priority == "70"
  })
  let levelFours = campaigns.filter(campaign => {
    return campaign.priority == "60"
  })
  let levelFives = campaigns.filter(campaign => {
    return campaign.priority == "50"
  })

  return {
    levelOnes,
    levelTwos,
    levelThrees,
    levelFours,
    levelFives,
  }
}
