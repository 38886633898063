import React, { useContext, useEffect, useState } from "react"
import { CampaignContext } from "../../contexts/CampaignContext"
import { campaignSorter } from "../../utils/CampaignSorter"
import Dropdown from "../Dropdown/Dropdown"
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal"
import classes from "./Campaignspreadsheet.module.css"
import { withAuthenticationRequired } from "@auth0/auth0-react"
import { useAuth0 } from "@auth0/auth0-react"

function CampaignSpreadsheet() {
  const [sortedCampaigns, setSortedCampaigns] = useState()
  const {
    campaigns,
    displayConfirmation,
    closeConfirmation,
    showConfirmation,
    modalParams,
    searchInput,
    filteredCampaigns,
    handleCampaigns,
    asfData,
  } = useContext(CampaignContext)

  const { user } = useAuth0()

  useEffect(() => {
    try {
      getAllCampaigns(user)
        .then(res => res.json())
        .then(json => handleCampaigns(json))
    } catch (err) {
      alert(err)
    }
  }, [])

  const getAllCampaigns = () => {
    const userToken = `${user.email}¿${user.sub}`
    return fetch("https://little-hypnotic-epoxy.glitch.me/get_all_campaigns", {
      headers: {
        authorization: userToken,
      },
    })
  }

  useEffect(() => {
    if (campaigns != undefined) {
      if (searchInput.length > 0) {
        if (filteredCampaigns.length > 0) {
          setSortedCampaigns(campaignSorter(filteredCampaigns))
        } else {
          setSortedCampaigns(null)
        }
      } else {
        setSortedCampaigns(campaignSorter(campaigns))
      }
    }
  }, [campaigns, searchInput])

  const handleEsc = e => {
    if (e.code === "Escape") closeConfirmation()
  }

  window.addEventListener("keydown", e => handleEsc(e))

  var modal
  if (showConfirmation && modalParams !== undefined) {
    modal = <ConfirmationModal />
  }

  const getAsfData = campaignName => {
    const asfObj = asfData?.[campaignName]

    if (asfObj) {
      return (
        <div className={classes.statWrapper}>
          <span className={classes.asf}>ASF:{asfObj.asf}</span>
          <span className={classes.asf}>Off'd:{asfObj.callsOffered}</span>
          <span className={classes.asf}>Ans'd:{asfObj.callsAnswered}</span>
          <span className={classes.asf}>VM:{asfObj.sentToVoicemail}</span>
          <span className={classes.asf}>SA:{asfObj.shortAbandons}</span>
        </div>
      )
    } else return <span className={classes.asf}>No calls</span>
  }

  return (
    <>
      {modal}
      {sortedCampaigns != undefined && (
        <div className={classes.table}>
          {/* LEVEL 1 CAMPAIGNS */}
          <div className={classes.column}>
            <p className={`${classes.header} ${classes.blue}`}>Level One (90)</p>
            {sortedCampaigns.levelOnes.map((campaign, i) => (
              <div className={classes.cell} key={i}>
                <div className={classes.campaignWrapper}>
                  <div className={classes.statWrapper}>{getAsfData(campaign.name)}</div>
                  <p className={classes.campaignName}>{campaign.name}</p>
                </div>
                <Dropdown campaign={campaign} />
              </div>
            ))}
          </div>
          {/* LEVEL 2 CAMPAIGNS */}
          <div className={classes.column}>
            <p className={`${classes.header} ${classes.green}`}>Level Two (80)</p>
            {sortedCampaigns.levelTwos.map((campaign, i) => (
              <div className={classes.cell} key={i}>
                <div className={classes.campaignWrapper}>
                  <div className={classes.statWrapper}>{getAsfData(campaign.name)}</div>
                  <p className={classes.campaignName}>{campaign.name}</p>
                </div>
                <Dropdown campaign={campaign} />
              </div>
            ))}
          </div>
          {/* LEVEL 3 CAMPAIGNS */}
          <div className={classes.column}>
            <p className={`${classes.header} ${classes.yellow}`}>Level Three (70)</p>
            {sortedCampaigns.levelThrees.map((campaign, i) => (
              <div className={classes.cell} key={i}>
                <div className={classes.campaignWrapper}>
                  <div className={classes.statWrapper}>{getAsfData(campaign.name)}</div>
                  <p className={classes.campaignName}>{campaign.name}</p>
                </div>
                <Dropdown campaign={campaign} />
              </div>
            ))}
          </div>
          {/* LEVEL 4 CAMPAIGNS */}
          <div className={classes.column}>
            <p className={`${classes.header} ${classes.grey}`}>Level Four (60)</p>
            {sortedCampaigns.levelFours.map((campaign, i) => (
              <div className={classes.cell} key={i}>
                <div className={classes.campaignWrapper}>
                  <div className={classes.statWrapper}>{getAsfData(campaign.name)}</div>
                  <p className={classes.campaignName}>{campaign.name}</p>
                </div>
                <Dropdown campaign={campaign} />
              </div>
            ))}
          </div>
          {/* LEVEL 5 CAMPAIGNS */}
          <div className={classes.column}>
            <p className={`${classes.header} ${classes.red}`}>Level Five (50)</p>
            {sortedCampaigns.levelFives.map((campaign, i) => (
              <div className={classes.cell} key={i}>
                <div className={classes.campaignWrapper}>
                  <div className={classes.statWrapper}>{getAsfData(campaign.name)}</div>
                  <p className={classes.campaignName}>{campaign.name}</p>
                </div>
                <Dropdown campaign={campaign} />
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  )
}

export default withAuthenticationRequired(CampaignSpreadsheet, {
  // Show a message while the user waits to be redirected to the login page.
  onRedirecting: () => (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        paddingBottom: "20vh",
      }}
    >
      Redirecting you to the login page...
    </div>
  ),
})
