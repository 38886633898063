import React, { useContext, useEffect, useState } from "react"
import { CampaignContext } from "../../contexts/CampaignContext"
import classes from "./Dropdown.module.css"
export default function Dropdown({ campaign }) {
  const [selectedPriority, setSelectedPriority] = useState()
  const { handleModalParams, openConfirmation } = useContext(CampaignContext)

  useEffect(() => {
    setSelectedPriority(campaign.priority)
  }, [])

  const handlePriorityChange = value => {
    setSelectedPriority(value)
    handleModalParams(campaign, value)
    openConfirmation()
  }

  return (
    <div className={classes.selectContainer}>
      {selectedPriority !== undefined && (
        <select
          value={campaign.priority}
          onChange={e => handlePriorityChange(e.target.value)}
        >
          <option value="90">Level 1 (90)</option>
          <option value="80">Level 2 (80)</option>
          <option value="70">Level 3 (70)</option>
          <option value="60">Level 4 (60)</option>
          <option value="50">Level 5 (50)</option>
        </select>
      )}
    </div>
  )
}
