import React, { useContext } from "react"
import { AiOutlineSearch } from "react-icons/ai"
import { CampaignContext } from "../../contexts/CampaignContext"
import classes from "./Searchbar.module.css"

//HiOutlineSearch
export default function Searchbar() {
  const { handleSearchInput } = useContext(CampaignContext)
  return (
    <div className={classes.container}>
      <input
        className={classes.input}
        placeholder="Find a campaign..."
        onChange={e => handleSearchInput(e.target.value)}
      />
      <div className={classes.icon}>
        <AiOutlineSearch />
      </div>
    </div>
  )
}
