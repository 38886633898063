import React, { useContext, useEffect } from "react"
import { changePriority } from "../../adapters/changePriority"
import { CampaignContext } from "../../contexts/CampaignContext"
import classes from "./ConfirmationModal.module.css"
import { useAuth0 } from "@auth0/auth0-react"
import close from "../../assets/close.svg"
import arrow from "../../assets/arrow.svg"

export default function ConfirmationModal() {
  const { modalParams, closeConfirmation, handleFive9Response } = useContext(CampaignContext)
  const { user } = useAuth0()

  const handlePriorityChange = async () => {
    if (modalParams.third_parameter === "Restricted") {
      alert("Cannot be used with campaigns that include Restricted as their third parameter")
    } else {
      closeConfirmation()
      const tokenAndEmail = `${user.email}¿${user.sub}`
      const result = await changePriority(modalParams, tokenAndEmail)
      handleFive9Response(modalParams, result)
    }
  }

  if (modalParams !== undefined) {
    const { currentCampaign, newPriority } = modalParams
    return (
      <div className={classes.modalBackdrop}>
        {currentCampaign !== undefined && (
          <div className={classes.modalForeground}>
            <div className={classes.top}>
              <p className={classes.campaignName}>{currentCampaign.name}</p>
              <img src={close} alt="close" className={classes.exit} onClick={closeConfirmation} />
            </div>
            <div className={classes.middle}>
              <div className={classes.priorityWrapper}>
                <p className={classes.priorityType}>Current Priority</p>
                <p className={classes.priority}>{currentCampaign.priority}</p>
              </div>
              <img src={arrow} alt="arrow" className={classes.arrow} />
              <div className={classes.priorityWrapper}>
                <p className={classes.priorityType}>New Priority</p>
                <p className={classes.priority}>{newPriority}</p>
              </div>
            </div>
            <div className={classes.bottom}>
              <p>
                {/* changing from {currentCampaign.priority} to {newPriority} */}
                <button onClick={handlePriorityChange} className={classes.confirm}>
                  Confirm Change
                </button>
              </p>
            </div>
          </div>
        )}
      </div>
    )
  } else {
    return <p></p>
  }
}
