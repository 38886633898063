const getAsf = async () => {
  try {
    const response = await fetch("https://little-hypnotic-epoxy.glitch.me/asf")
    const json = response.json()
    return json
  } catch (err) {
    console.log(err)
  }
}

export default getAsf
