import React from "react"
import CampaignContextProvider from "./contexts/CampaignContext"
import CampaignSpreadsheet from "./components/Campaignspreadsheet/CampaignSpreadsheet"
import "./global.css"
import Layout from "./components/Layout/Layout"
import { Auth0Provider } from "@auth0/auth0-react"
import LoginButton from "./components/LoginButton/LoginButton"
import PrivateRoute from "./components/PrivateRoute"
import LogoutButton from "./components/LogoutButton/LogoutButton"

export default function App() {
  return (
    <div>
      <Auth0Provider
        domain="dev-d9upn3zn.us.auth0.com"
        clientId="c4FzZ33PwgXTaJ122t8Z1ArS5y4BOkB9"
        redirectUri={window.location.origin}
        // redirectUri="https://inbound-priority-changer.dgaauto.com"
        // redirectUri="http://localhost:3000"
      >
        <CampaignContextProvider>
          <Layout>
            <CampaignSpreadsheet />
            {/* <PrivateRoute /> */}
          </Layout>
        </CampaignContextProvider>
      </Auth0Provider>
    </div>
  )
}
