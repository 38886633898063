import React from "react"
import Navbar from "../Navbar/Navbar"

export default function Layout({ children }) {
  return (
    <div>
      <div style={{ marginBottom: "8.5vh" }}>
        <Navbar />
      </div>
      {children}
    </div>
  )
}
