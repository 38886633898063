import React from "react"
import logo from "../../assets/logo.png"
import LogoutButton from "../LogoutButton/LogoutButton"
import Notification from "../Notification/Notification"
import Searchbar from "../Searchbar/Searchbar"
import classes from "./Navbar.module.css"

export default function Navbar() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }

  return (
    <nav className={classes.nav}>
      <img
        src={logo}
        alt="logo"
        className={classes.logo}
        onClick={scrollToTop}
      />
      <div className={classes.right}>
        {/* <LogoutButton /> */}
        <Notification />
        <Searchbar />
      </div>
    </nav>
  )
}
