import { createContext, useState, useEffect } from "react"
import { getAllCampaigns } from "../adapters/getAllCampaigns"
import getAsf from "../adapters/getAsf"

export const CampaignContext = createContext()

const CampaignContextProvider = ({ children }) => {
  const [campaignData, setCampaignData] = useState()
  const [parsedCampaignData, setParsedCampaignData] = useState()
  const [campaignInfoStorage, setCampaignInfoStorage] = useState()
  const [campaigns, setCampaigns] = useState()
  const [currentCampaign, setCurrentCampaign] = useState()
  const [submitReady, setSubmitReady] = useState(false)
  const [requestParams, setRequestParams] = useState({})
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [modalParams, setModalParams] = useState()
  const [searchInput, setSearchInput] = useState("")
  const [filteredCampaigns, setFilteredCampaigns] = useState([])
  const [changeLog, setChangeLog] = useState([])
  const [asfData, setAsfData] = useState()

  useEffect(() => {
    if (campaigns) {
      console.log(campaigns)
      handleAsfFetch()
    }
  }, [campaigns])

  useEffect(() => {
    console.log(asfData)
  }, [asfData])

  const handleAsfFetch = async () => {
    const newAsfData = await getAsf()
    setAsfData(newAsfData)
  }

  const changeCurrentCampaign = campaign => setCurrentCampaign(campaign)

  const handleRequestParams = obj => {
    setRequestParams(obj)
    setSubmitReady(true)
  }

  const changeRequestParamLevel = newLevel => {
    setRequestParams({ ...requestParams, level: newLevel })
  }

  const openConfirmation = () => setShowConfirmation(true)
  const closeConfirmation = () => setShowConfirmation(false)

  const handleModalParams = (campaign, newPriority) => {
    setModalParams({ currentCampaign: campaign, newPriority: newPriority })
  }

  const handleSearchInput = value => {
    setSearchInput(value)
    filterCampaigns()
  }

  const handleFive9Response = (params, status) => {
    if (typeof params === "object") {
      const { name: campaign, priority: oldPriority } = params.currentCampaign
      const { newPriority } = params

      const increasedOrDecreased =
        parseInt(oldPriority) > parseInt(newPriority)
          ? "decreased"
          : "increased"

      if (status == 200) {
        const record = `${campaign} ${increasedOrDecreased} to ${newPriority}`
        setChangeLog(changeLog => [...changeLog, record])
        const newCampaigns = campaigns.map(obj => {
          if (obj.name === campaign) {
            let currentCampaign = { ...obj }
            currentCampaign.priority = newPriority
            return currentCampaign
          } else return obj
        })
        setCampaigns(newCampaigns)
      } else {
        const record = `Failed to change ${campaign} priority`
        alert(`Failed to change ${campaign} priority`)
        setChangeLog(changeLog => [...changeLog, record])
        setCampaigns(campaigns => campaigns)
      }
    } else alert("line 64 - handleFive9Response params not an object")
  }

  useEffect(() => {
    filterCampaigns()
  }, [searchInput])

  const filterCampaigns = () => {
    if (campaigns !== undefined) {
      let newCampaignResults = campaigns.filter(campaign => {
        if (campaign.name.toLowerCase().includes(searchInput.toLowerCase())) {
          return campaign
        }
      })

      setFilteredCampaigns(newCampaignResults)
    }
  }

  const handleCampaigns = campaignResults => setCampaigns(campaignResults)

  return (
    <div>
      <CampaignContext.Provider
        value={{
          campaignData,
          setCampaignData,
          parsedCampaignData,
          setParsedCampaignData,
          campaignInfoStorage,
          setCampaignInfoStorage,
          campaigns,
          currentCampaign,
          submitReady,
          handleRequestParams,
          requestParams,
          changeRequestParamLevel,
          closeConfirmation,
          showConfirmation,
          changeCurrentCampaign,
          handleModalParams,
          modalParams,
          openConfirmation,
          handleSearchInput,
          searchInput,
          filteredCampaigns,
          handleCampaigns,
          handleFive9Response,
          changeLog,
          asfData,
        }}
      >
        {children}
      </CampaignContext.Provider>
    </div>
  )
}

export default CampaignContextProvider
